import {
  Banner,
  Button,
  Icon,
  Layout,
  Card,
  Tabs,
  Modal,
  Page,
  Popover,
  Spinner,
  Text,
  VerticalStack,
  HorizontalStack,
} from "@shopify/polaris";
import { MobileHorizontalDotsMajor } from "@shopify/polaris-icons";
import { Box, MediaMinWidths } from "@smartrr/shared/components/primitives";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IPaymentMethod } from "@smartrr/shared/entities/PaymentMethod";
import { ISubscriptionEvent } from "@smartrr/shared/entities/SubscriptionEvent";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";
import { toViewDate } from "@smartrr/shared/utils/renderViewDate";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import { CustomerInformationCard } from "@vendor-app/app/_sharedComponents/CustomerInformationCard";
import { Events } from "@vendor-app/app/_sharedComponents/Events";
import { CardIcon } from "@vendor-app/app/_sharedComponents/Icons";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { ViewAsCustomer } from "@vendor-app/app/_sharedComponents/ViewAsCustomer";
import { loadCustomer } from "@vendor-app/app/_state/actionCreators/customers";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { redirectToShopify } from "@vendor-app/utils/redirectToShopify";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { CustomerOrderHistory } from "./components/CustomerOrderHistory";
import { CustomerSubscriptionsTable } from "./components/CustomerSubscriptionsTable";
import { CustomerTransactionHistory } from "./components/CustomerTransactionHistory";
import {
  CustomerTablesTabs,
  DarkCardContainer,
  ICustomerDetailsProps,
  QuickJumpContainer,
  customerTablesTabs,
} from "../libs";
import { LoyaltyAndBirthdayCard } from "./components/LoyaltyAndBirthdayCard";
import { EraseCustomerData } from "./components/EraseCustomerData/EraseCustomerData";
import { EraseCustomerDataModalBannerWrapper } from "./components/styled/EraseCustomerDataModalBannerWrapper";
import { useShopifyCustomerData } from "./libs/hooks/useShopifyCustomerData";
import { useCustomerOrderCount } from "./libs/hooks/useCustomerOrderCount";

const PageWrapper = styled.div`
  > .Polaris-Page--narrowWidth {
    max-width: 58rem;
  }
`;

export const CustomerDetails = ({ customerShopifyId }: ICustomerDetailsProps): JSX.Element => {
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();
  const isMobile = useMediaQuery({
    maxWidth: MediaMinWidths.Medium,
  });
  const activeOrg = useActiveOrganizationSelector();
  const user = useSmartrrVendorSelector(state => state.auth.user);

  const { isLoading: isCustomerLoading } = useSmartrrVendorSelector(state => state.customerRelationships);
  const customer = useSmartrrVendorSelector(state =>
    state.customerRelationships.data.find(
      custRel => custRel.shopifyId === ensureShopifyGid("Customer", customerShopifyId)
    )
  );

  const [openEraseCustomerDataModal, setOpenEraseCustomerDataModal] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);

  const [paymentPopoverIndex, setPaymentPopoverIndex] = useState<number | undefined>();

  const [customerEvents, setCustomerEvents] = useState<ISubscriptionEvent[]>([]);

  const [customerEventsLoading, setCustomerEventsLoading] = useState<boolean>(false);
  const [selectedTableTab, setSelectedTableTab] = useState(CustomerTablesTabs.Subscriptions);
  const { isShopifyCustomerDataLoading, shopifyCustomerData } = useShopifyCustomerData(customerShopifyId);
  const orderCount = useCustomerOrderCount(customerShopifyId);

  useEffect(() => {
    dispatch(loadCustomer(customerShopifyId));
  }, [dispatch, loadCustomer, customerShopifyId]);

  useEffect(() => {
    typedFrontendVendorApi
      .getReq("/purchase-state/:customerShopifyId/customer-events", {
        params: {
          customerShopifyId,
        },
      })
      .then(res => {
        if (res.type === "success") {
          setCustomerEvents(res.body);
        }
        if (res.type === "error") {
          addToast("Error fetching customer events");
        }
      })
      .catch(error => {
        console.error(error);
        addToast("Error fetching customer events");
      })
      .finally(() => {
        setCustomerEventsLoading(false);
      });
  }, [customerShopifyId]);

  useEffect(() => {
    if (customer?.id) {
      typedFrontendVendorApi
        .getReq("/customer-relationship/:customerRelationshipId/payment-methods", {
          params: {
            customerRelationshipId: customer.id,
          },
        })
        .then(res => {
          if (res.type === "success") {
            setPaymentMethods(res.body);
          }
          if (res.type === "error") {
            addToast("Error fetching payment methods");
          }
        })
        .catch(error => {
          console.error(error);
          addToast("Error fetching payment methods");
        });
    }
  }, [addToast, customer?.id]);

  const getCpsStatuses = async (email?: string) => {
    getCPSStatusesBlock: try {
      if (!email) {
        break getCPSStatusesBlock;
      }

      return await typedFrontendVendorApi.getReq(
        `/purchase-state?pageSize=1&pageNumber=0&filterIn[status][0]=ACTIVE&filterLike[emailOrName]=${encodeURIComponent(
          email
        )}`
      );
    } catch (error) {
      console.error(error);
      addToast("Error fetching subscription's statuses");
    }
  };

  const sendLinkToUpdatePaymentMethod = async (shopifyId: string | undefined) => {
    if (shopifyId && customer?.id) {
      typedFrontendVendorApi
        .putReq(
          "/customer-relationship/:customerRelationshipId/send-update-payment-method-email/:paymentMethodId",
          {
            params: {
              customerRelationshipId: customer.id,
              paymentMethodId: shopifyId,
            },
          }
        )
        .then(res => {
          if (res.type === "success") {
            addToast("Update payment method link sent to customer");
          }
          if (res.type === "error") {
            addToast("Error sending update payment method link");
          }
        })
        .catch(error => {
          console.error(error);
          addToast("Error sending update payment method link");
        });
    }
  };

  const eraseCustomerData = async () => {
    if (!customer?.id) {
      addToast("Cant find customer's id");
      return;
    }

    await typedFrontendVendorApi
      .putReq("/customer/:customerRelationshipId/ccpa", {
        params: {
          customerRelationshipId: customer.id,
        },
      })
      .then(res => {
        if (res.type === "success") {
          navigateWithShopInQuery(`${adminRoutePrefix}/customers`);
          addToast(`${customer.firstName} ${customer.lastName} erased `);
        }
        if (res.type === "error") {
          addToast("Error erasing customer's data");
        }
      })
      .catch(error => {
        console.error(error);
        addToast("Error erasing customer's data");
      });
  };

  const handleEraseCustomerDataClick = async () => {
    await getCpsStatuses(customer?.email!).then(res => {
      if (res && res.type == "success" && res.body.totalCount > 0) {
        addToast("Customer has an active subscription", true);
      } else if (res && res.type === "error") {
        addToast("Error fetching subscription's statuses");
      } else {
        setOpenEraseCustomerDataModal(true);
      }
    });
  };

  if (isCustomerLoading || !customer) {
    return (
      <Box style={{ height: "100vh" }} alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <PageWrapper>
      <Page
        narrowWidth
        title={`${customer.firstName} ${customer.lastName}`}
        subtitle={`Subscriber since ${toViewDate(ISODateString.fromString(customer.createdDate).toJSDate(), {
          year: "numeric",
          day: "2-digit",
          month: "long",
        })}`}
        backAction={{
          onAction: () => navigateWithShopInQuery(`${adminRoutePrefix}/customers`),
          content: "Back to customers",
        }}
        primaryAction={
          <Box
            vertical={isMobile}
            gap={1}
            justifyContent="stretch"
            style={{ width: isMobile ? "100%" : undefined }}
          >
            <Button external plain onClick={() => redirectToShopify(activeOrg, "customers", customer?.shopifyId)}>
              View in Shopify
            </Button>
          </Box>
        }
      >
        <Layout>
          <Layout.Section>
            <VerticalStack gap="3">
              {isShopifyCustomerDataLoading ? (
                <HorizontalStack align="center" blockAlign="center">
                  <Spinner />
                </HorizontalStack>
              ) : (
                !!shopifyCustomerData && (
                  <DarkCardContainer>
                    <Card>
                      <Text variant="headingMd" as="h2">
                        Lifetime spend
                      </Text>
                      <Text variant="headingLg" as="p">
                        {formatMoney(
                          shopifyCustomerData.amountSpent.amount * 100,
                          shopifyCustomerData.amountSpent.currencyCode
                        )}
                      </Text>
                    </Card>
                    <Card>
                      <Text variant="headingMd" as="h2">
                        Total subscription orders
                      </Text>
                      <Text variant="headingLg" as="p">
                        {orderCount}
                      </Text>
                    </Card>
                  </DarkCardContainer>
                )
              )}

              <Card padding={"0"}>
                <Tabs fitted selected={selectedTableTab} tabs={customerTablesTabs} onSelect={setSelectedTableTab}>
                  {selectedTableTab === CustomerTablesTabs.Subscriptions && (
                    <CustomerSubscriptionsTable customer={customer} activeOrg={activeOrg} />
                  )}
                  {selectedTableTab === CustomerTablesTabs.Orders && (
                    <CustomerOrderHistory customer={customer} activeOrg={activeOrg} />
                  )}
                  {selectedTableTab === CustomerTablesTabs.Transactions && (
                    <CustomerTransactionHistory customer={customer} activeOrg={activeOrg} />
                  )}
                </Tabs>
              </Card>
            </VerticalStack>
            <Events
              hideComments
              shopifyEvents={shopifyCustomerData?.events.edges.map(event => ({ ...event.node })).reverse() ?? []}
              subscriptionEvents={customerEvents}
              loading={customerEventsLoading}
            />
          </Layout.Section>
          <Layout.Section secondary>
            <QuickJumpContainer
              style={{ position: "relative" }}
              sx={{
                ".Polaris-Card": {
                  maxHeight: "560px",
                },
                ".Polaris-Box": {
                  maxWidth: "282px",
                  margin: "16px 0",
                },
              }}
            >
              <CustomerInformationCard
                redirectToShopify
                customerInfo={customer}
                redirectToCustomerShopify={id => redirectToShopify(activeOrg, "customers", id)}
              />
              {customer.shopifyId ? <ViewAsCustomer customerShopifyId={customer.shopifyId} user={user} /> : null}
              <Card>
                <VerticalStack gap={"4"}>
                  <Text variant="headingMd" as="h2">
                    Payment methods
                  </Text>
                  {paymentMethods.length ? (
                    <React.Fragment>
                      {paymentMethods.map((payMthd, index) => (
                        <HorizontalStack align="space-between" wrap={false} key={index}>
                          <HorizontalStack gap={"6"} align="space-between" wrap={false}>
                            <Icon source={CardIcon} />
                            <VerticalStack align="center">
                              <span>
                                {capitalize(payMthd?.cardBrand)} ending in {payMthd?.lastDigits}
                              </span>
                              <Text variant="bodyMd" as="span" color="subdued">
                                Expires {payMthd?.cardExpirationMonth}/
                                {String(payMthd?.cardExpirationYear || "").slice(-2)}
                              </Text>
                            </VerticalStack>
                          </HorizontalStack>
                          <Popover
                            active={paymentPopoverIndex === index}
                            activator={
                              <Button
                                plain
                                removeUnderline
                                icon={MobileHorizontalDotsMajor}
                                onClick={() => {
                                  setPaymentPopoverIndex(index === paymentPopoverIndex ? undefined : index);
                                }}
                              />
                            }
                            onClose={() => setPaymentPopoverIndex(undefined)}
                          >
                            <Popover.Section>
                              <Button
                                plain
                                removeUnderline
                                monochrome
                                onClick={() => {
                                  sendLinkToUpdatePaymentMethod(payMthd.shopifyId);
                                  setPaymentPopoverIndex(undefined);
                                }}
                              >
                                Send link to update card
                              </Button>
                            </Popover.Section>
                          </Popover>
                        </HorizontalStack>
                      ))}
                    </React.Fragment>
                  ) : (
                    <VerticalStack>
                      <Text variant="bodyMd" as="span" color="subdued">
                        No payment methods
                      </Text>
                    </VerticalStack>
                  )}
                </VerticalStack>
              </Card>
              <LoyaltyAndBirthdayCard customer={customer} />
              <EraseCustomerData handleEraseCustomerDataClick={handleEraseCustomerDataClick} />
            </QuickJumpContainer>
          </Layout.Section>
        </Layout>
        <Modal
          sectioned
          open={openEraseCustomerDataModal}
          title="Warning"
          onClose={() => setOpenEraseCustomerDataModal(false)}
          primaryAction={{
            id: "smartrr-erase-customer-data-modal-go-back",
            content: "Go back",
            onAction: () => setOpenEraseCustomerDataModal(false),
          }}
          secondaryActions={[
            {
              id: "smartrr-erase-customer-data-modal-confirm",
              content: "Erase all customer data",
              onAction: eraseCustomerData,
              destructive: true,
            },
          ]}
        >
          <EraseCustomerDataModalBannerWrapper id="smartrr-erase-customer-data-modal-banner">
            <Banner status="critical">
              <Text id="smartrr-erase-customer-data-modal-text-1" as="p" variant="bodyMd">
                This action will erase this customer&apos;s personal and subscription data from Smartrr&apos;s
                database and can&apos;t be undone. Are you sure you wish to continue?
              </Text>
              &nbsp;
              <Text id="smartrr-erase-customer-data-modal-text-2" as="p" variant="bodyMd" color="subdued">
                Note: This action won&apos;t delete any customer data stored in Shopify. To delete all customer
                data in accordance with privacy laws, be sure to also visit your Shopify admin and erase customer
                personal data there.
              </Text>
            </Banner>
          </EraseCustomerDataModalBannerWrapper>
        </Modal>
      </Page>
    </PageWrapper>
  );
};
