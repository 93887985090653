import { Request } from "express";
import { ISODateString } from "./ISODateString";
import { IPurchaseState } from "./PurchaseState";
import { IVendorUser } from "./VendorUser";
import { ICustomerRelationship } from "./CustomerRelationship";
import { CurrencyCode } from "@smartrr/shared/currencyCode";

export enum SubscriptionEventTypeEnum {
  CHANGE_ORDER_DATE = "CHANGE_ORDER_DATE",
  CHANGE_SELLING_PLAN = "CHANGE_SELLING_PLAN",
  PAUSE = "PAUSE",
  CANCEL = "CANCEL",
  CANCEL_PREPAID = "CANCEL_PREPAID",
  EXPIRED = "EXPIRED",
  ACTIVATE = "ACTIVATE",
  FAILED_DISCOUNT = "FAILED_DISCOUNT",
  SUCCESSFUL_DISCOUNT = "SUCCESSFUL_DISCOUNT",
  SUCCESSFUL_BILLING_ATTEMPT = "SUCCESSFUL_BILLING_ATTEMPT",
  FAILED_BILLING_ATTEMPT = "FAILED_BILLING_ATTEMPT",
  CREATE_BILL = "CREATE_BILL",
  SKIP = "SKIP",
  UNSKIP = "UNSKIP",
  GIFT = "GIFT",
  UNDO_GIFT = "UNDO_GIFT",
  ADD_ADDRESS = "ADD_ADDRESS",
  CHANGE_ADDRESS = "CHANGE_ADDRESS",
  DELETE_ADDRESS = "DELETE_ADDRESS",
  UPDATE_ADDRESS = "UPDATE_ADDRESS",
  REMOVE_DISCOUNT = "REMOVE_DISCOUNT",
  REMOVE_LINE_ITEM = "REMOVE_LINE_ITEM",
  UPDATE_LINE_ITEM = "UPDATE_LINE_ITEM",
  ADD_LINE_ITEM = "ADD_LINE_ITEM",
  CHANGE_SHIPPING_PRICE = "CHANGE_SHIPPING_PRICE",
  SWAP_LINE_ITEM = "SWAP_LINE_ITEM",
  FAILED_CHANGE_ORDER_DATE = "FAILED_CHANGE_ORDER_DATE",
  FAILED_CHANGE_SELLING_PLAN = "FAILED_CHANGE_SELLING_PLAN",
  FAILED_PAUSE = "FAILED_PAUSE",
  FAILED_CANCEL = "FAILED_CANCEL",
  FAILED_ACTIVATE = "FAILED_ACTIVATE",
  FAILED_SKIP = "FAILED_SKIP",
  FAILED_UNSKIP = "FAILED_UNSKIP",
  FAILED_GIFT = "FAILED_GIFT",
  FAILED_UNDO_GIFT = "FAILED_UNDO_GIFT",
  FAILED_ADD_ADDRESS = "FAILED_ADD_ADDRESS",
  FAILED_UPDATE_ADDRESS = "FAILED_UPDATE_ADDRESS",
  FAILED_CHANGE_ADDRESS = "FAILED_CHANGE_ADDRESS",
  FAILED_DELETE_ADDRESS = "FAILED_DELETE_ADDRESS",
  FAILED_REMOVE_DISCOUNT = "FAILED_REMOVE_DISCOUNT",
  FAILED_REMOVE_LINE_ITEM = "FAILED_REMOVE_LINE_ITEM",
  FAILED_UPDATE_LINE_ITEM = "FAILED_UPDATE_LINE_ITEM",
  FAILED_ADD_LINE_ITEM = "FAILED_ADD_LINE_ITEM",
  FAILED_CHANGE_SHIPPING_PRICE = "FAILED_CHANGE_SHIPPING_PRICE",
  FAILED_SWAP_LINE_ITEM = "FAILED_SWAP_LINE_ITEM",
  OOS_AUTOMATIC_PAUSE = "OOS_AUTOMATIC_PAUSE",
  UNAVAILABLE_AUTOMATIC_PAUSE = "UNAVAILABLE_AUTOMATIC_PAUSE",
  OOS_AUTOMATIC_SKIP = "OOS_AUTOMATIC_SKIP",
  REWARDS_REDEEMED_PRODUCT = "REWARDS_REDEEMED_PRODUCT",
  REWARDS_REDEEMED_INCENTIVE = "REWARDS_REDEEMED_INCENTIVE",
  REWARDS_POINTS_EARNED = "REWARDS_POINTS_EARNED",
  REWARDS_POINTS_CORRECTION = "REWARDS_POINTS_CORRECTION",
  UPDATE_EXTERNAL_SUBSCRIPTION_DATES = "UPDATE_EXTERNAL_SUBSCRIPTION_DATES",
  FAILED_UPDATE_EXTERNAL_SUBSCRIPTION_DATES = "FAILED_UPDATE_EXTERNAL_SUBSCRIPTION_DATES",
  NOTIFICATION_SUBS_CREATED_NEW = "NOTIFICATION_SUBS_CREATED_NEW",
  NOTIFICATION_SUBS_CREATED_EXISTING = "NOTIFICATION_SUBS_CREATED_EXISTING",
  NOTIFICATION_PAYMENT_FAILED = "NOTIFICATION_PAYMENT_FAILED",
  NOTIFICATION_SUBSCRIPTION_CANCELED = "NOTIFICATION_SUBSCRIPTION_CANCELED",
  NOTIFICATION_SUBSCRIPTION_PAUSED = "NOTIFICATION_SUBSCRIPTION_PAUSED",
  NOTIFICATION_SUBSCRIPTION_ACTIVATED = "NOTIFICATION_SUBSCRIPTION_ACTIVATED",
  NOTIFICATION_UPCOMING_ORDER = "NOTIFICATION_UPCOMING_ORDER",
  NOTIFICATION_PAYMENT_EXPIRATION = "NOTIFICATION_PAYMENT_EXPIRATION",
  NOTIFICATION_ITEM_OUT_OF_STOCK = "NOTIFICATION_ITEM_OUT_OF_STOCK",
  NOTIFICATION_GIFT_CREATED = "NOTIFICATION_GIFT_CREATED",
  NOTIFICATION_GIFT_SHIPPING = "NOTIFICATION_GIFT_SHIPPING",
  BILL_CREATION_FAILED = "BILL_CREATION_FAILED",
  AUTO_RESUMED = "AUTO_RESUMED",
  ADMIN_LOGGEDIN_AS_CUSTOMER = "ADMIN_LOGGEDIN_AS_CUSTOMER",
  SUPER_USER_LOGGEDIN_AS_CUSTOMER = "SUPER_USER_LOGGEDIN_AS_CUSTOMER",
  ADMIN_ADDED_COMMENT = "ADMIN_ADDED_COMMENT",
  SUCCESSFUL_REFERRAL = "SUCCESSFUL_REFERRAL",
  CHANGE_EMAIL = "CHANGE_EMAIL",
  SEQUENTIAL_SWAP = "SEQUENTIAL_SWAP",
  SEQUENTIAL_REMOVE_PRODUCT = "SEQUENTIAL_REMOVE_PRODUCT",
  SEQUENTIAL_TERMINATE_CONTRACT = "SEQUENTIAL_TERMINATE_CONTRACT",
  SEQUENTIAL_RESTART_SEQUENCE = "SEQUENTIAL_RESTART_SEQUENCE",
  AUTO_CANCELED = "AUTO_CANCELED",
}
export type SubscriptionEventType = `${SubscriptionEventTypeEnum}` | SubscriptionEventTypeEnum;

export enum SubscriptionEventCreatorEnum {
  CUSTOMER = "CUSTOMER",
  AGENT = "AGENT",
  SCRIPT = "SCRIPT",
  SEQUENTIAL = "SEQUENTIAL",
  SMARTRR = "SMARTRR",
  API = "API",
}
export type SubscriptionEventCreator = `${SubscriptionEventCreatorEnum}` | SubscriptionEventCreatorEnum;

export interface ISubscriptionEventCreatorData {
  email: string;
  firstName: string;
  lastName: string;
}

export interface ISubscriptionEventData {
  purchaseState?: IPurchaseState;
  previousShippingPrice?: number;
  updatedShippingPrice?: number;
  discountCode?: string;
  address?: object;
  sellingPlanId?: string;
  discountShopifyId?: string;
  billEventType?: string;
  orderDate?: ISODateString;
  billId?: string;
  addedLineItem?: {
    quantity?: number;
    variantId?: string;
    sellingPlanId?: string;
    skdIdx?: number;
    price?: number;
    orderDate?: ISODateString;
    productName?: string;
    variantName?: string;
  };
  updatedLineItem?: {
    lineItemId: string;
    variantId?: string;
    sellingPlanId?: string;
    updatedPrice?: number;
    productName?: string;
    updatedVariantName?: string;
    variantName?: string;
    updatedQuantity?: number;
    previousQuantity?: number;
  };
  removedLineItem?: {
    lineItemId: string;
    orderDate?: ISODateString;
    productName?: string;
    variantName?: string;
    quantity?: number;
  };
  errorDetails?: {
    message: string;
    status: number;
  };
  rewardDetails?: {
    rewardPoints: number;
    eventName: string;
    orderId?: string;
    pointBalance?: number;
    unitName?: string;
    refereeName?: string;
  };
  redeemedItemDetails?: {
    name: string;
    orderDate: ISODateString;
    pointsAmount: number;
    pointsName: string;
    finalPoints: number;
    previousPoints: number;
  };
  updatedExternalSubcriptionDates?: {
    externalSubscriptionCreatedDate?: ISODateString;
    previousExternalSubscriptionCreatedDate?: ISODateString;
    externalSubscriptionCancelledAt?: ISODateString;
    previousExternalSubscriptionCancelledAt?: ISODateString;
  };
  autoUnpauseReason?: string;
  loginData?: {
    firstName: string;
    lastName: string;
    email: string;
  };
  comment?: {
    createdAt: ISODateString;
    isPinned: boolean;
    authorName: string;
    text: string;
  };
  updateCustomerInfo?: {
    oldEmail?: string;
    currentEmail?: string;
  };
  restartSequence?: {
    lineItemId: string;
    sequenceId: string;
    restartFromSequenceNumber: number;
    previousSequenceNumber: number;
  };
}

export interface ISubscriptionEvent {
  id: string;
  eventData?: ISubscriptionEventData;

  // if createdBy wasn't already a pre-existing enum column, I'd combine createdBy and creatorData into one obj
  createdBy: SubscriptionEventCreator;
  creatorData?: ISubscriptionEventCreatorData | undefined;

  eventDate: ISODateString;
  eventType: SubscriptionEventType;
}

export interface ISubscriptionEventWithPurchaseStateInformation extends ISubscriptionEvent {
  currency?: CurrencyCode;
}

export const getEventCreatorByString = (q: string): SubscriptionEventCreator =>
  q ? SubscriptionEventCreatorEnum.AGENT : SubscriptionEventCreatorEnum.CUSTOMER;

export const getCreatorDataFromReq = (req: Request<Record<string, any>, any, any, any, Record<string, any>>) => {
  // @ts-ignore
  if (!req.user) {
    return undefined;
  }

  // @ts-ignore - User exists on Express.Request there just isn't a type available to cast user to
  // We have no way of conditionally using one function over the other with typeof, so using present values for conditional logic
  if ((req.user as IVendorUser).originalFirstName && (req.user as IVendorUser).originalEmail) {
    // @ts-ignore
    return formatVendorCreatorDataForEventLog(req.user);
    // @ts-ignore
  } else if ((req.user as ICustomerRelationship).firstName && (req.user as ICustomerRelationship).email) {
    // @ts-ignore
    return formatCustRelCreatorDataForEventLog(req.user);
  }
};

/**
 * IVendorUser / VendorUserOrm / ICustomerRelationship / CustRelOrm
 * We don't need all ORM data stored within creatorData so these utils
 * grab only what we need to render the event logs
 */
export const formatVendorCreatorDataForEventLog = (user?: IVendorUser): ISubscriptionEventCreatorData => ({
  email: user?.originalEmail ?? "",
  firstName: user?.originalFirstName ?? "",
  lastName: user?.originalLastName ?? "",
});

export const formatCustRelCreatorDataForEventLog = (
  user?: ICustomerRelationship
): ISubscriptionEventCreatorData => ({
  email: user?.email ?? "",
  firstName: user?.firstName ?? "",
  lastName: user?.lastName ?? "",
});

export const getCreatorData = (
  custRel: ICustomerRelationship | undefined,
  req: Request<Record<string, any>, any, any, any, Record<string, any>>
) => (custRel ? formatCustRelCreatorDataForEventLog(custRel) : getCreatorDataFromReq(req));
