import { Card, Text, VerticalStack } from "@shopify/polaris";
import { AddWidgetButton } from "../AddWidgetButton";
import { ThemeSelect } from "../ThemeSelect";
import { SetupStoreAccess } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/store";
import { useCallback, useMemo, useState } from "react";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { parseShopName } from "@smartrr/shared/utils/parseShopName";
import { ContactSupportBanner } from "../ContactSupportBanner";
import { useThemeCustomizationUrl } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/utils";

export const AddSubscriptionWidgetCard = () => {
  const [isThemeValid, setIsThemeValid] = useState<boolean>(true);
  const org = useActiveOrganizationSelector();
  const themes = SetupStoreAccess.useThemes();
  const { validateTheme } = SetupStoreAccess.useActions();
  const { shopName } = parseShopName(org?.myShopifyDomain);
  const [themeId, setThemeId] = useState<string>("");

  // Linking merchants to their collections page
  const themeCustomizeLink = useThemeCustomizationUrl(shopName, themeId, "/products");

  const disabled = useMemo(
    () => !isThemeValid || !themes.length || !themeId,
    [isThemeValid, themes.length, themeId]
  );

  const onSelect = useCallback(async (id: string) => {
    const isValid = await validateTheme(id, "templates/product.json");
    setIsThemeValid(isValid);
    setThemeId(id);
  }, []);

  return (
    <Card>
      <VerticalStack gap={"3"}>
        <Text as="h2" variant="headingMd">
          1. Add subscription widget
        </Text>
        {!isThemeValid && <ContactSupportBanner widgetType="subscription" />}
        <VerticalStack gap={"4"}>
          <Text as="p">
            Use the Smartrr subscription widget to customize how subscription options appear on product pages.
          </Text>
          <ThemeSelect onSelect={onSelect} />
          <AddWidgetButton url={themeCustomizeLink} disabled={disabled} />
        </VerticalStack>
      </VerticalStack>
    </Card>
  );
};
