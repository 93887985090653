import { ICreateEntity, ISharedEntityFields } from "./shared/SharedEntityFields";

// TODO: ideally we want to have these values in sync with ApplicationEventType
export enum WebhookEventTypeEnum {
  SUBSCRIPTION_CREATE = "SUBSCRIPTION_CREATE",
  SUBSCRIPTION_ACTIVATE = "SUBSCRIPTION_ACTIVATE",
  SUBSCRIPTION_CANCEL = "SUBSCRIPTION_CANCEL",
  SUBSCRIPTION_LINE_UPDATE = "SUBSCRIPTION_LINE_UPDATE",
  SUBSCRIPTION_ITEM_OUT_OF_STOCK = "SUBSCRIPTION_ITEM_OUT_OF_STOCK",
  SUBSCRIPTION_PRODUCT_UNAVAILABLE = "SUBSCRIPTION_PRODUCT_UNAVAILABLE",
  SUBSCRIPTION_SKIP = "SUBSCRIPTION_SKIP",
  SUBSCRIPTION_UNSKIP = "SUBSCRIPTION_UNSKIP",
  SUBSCRIPTION_GIFT = "SUBSCRIPTION_GIFT",
  SUBSCRIPTION_PAUSE = "SUBSCRIPTION_PAUSE",
  SUBSCRIPTION_AUTO_RESUME = "SUBSCRIPTION_AUTO_RESUME",
  SUBSCRIPTION_SET_NEXT_DATE = "SUBSCRIPTION_SET_NEXT_DATE",
  SUBSCRIPTION_SEND_NOW = "SUBSCRIPTION_SEND_NOW",
  SUBSCRIPTION_UPCOMING_ORDER = "SUBSCRIPTION_UPCOMING_ORDER",
  PAYMENT_EXPIRED_NOTIFICATION = "PAYMENT_EXPIRED_NOTIFICATION",
  SUBSCRIPTION_FAILED_PAYMENT = "SUBSCRIPTION_FAILED_PAYMENT",
  SUBSCRIPTION_SUCCESSFUL_PAYMENT = "SUBSCRIPTION_SUCCESSFUL_PAYMENT",
  PAYMENT_METHOD_UPDATE = "PAYMENT_METHOD_UPDATE",
  BUNDLE_COMPLETE = "BUNDLE_COMPLETE",
  CUSTOMER_OTP_AUTH_CREATED = "CUSTOMER_OTP_AUTH_CREATED",
  CUSTOMER_MAGIC_LINK_AUTH_CREATED = "CUSTOMER_MAGIC_LINK_AUTH_CREATED",
  LOYALTY_POINTS_EARNED = "LOYALTY_POINTS_EARNED",
  REFERRAL_CODE_GENERATED = "REFERRAL_CODE_GENERATED",
  LOYALTY_BONUS_EARNED = "LOYALTY_BONUS_EARNED",
  LOYALTY_POINTS_REDEEMED = "LOYALTY_POINTS_REDEEMED",
  LOYALTY_TIER_ADVANCEMENT = "LOYALTY_TIER_ADVANCEMENT",
  SUCCESSFUL_REFERRAL = "SUCCESSFUL_REFERRAL",
  SUBSCRIPTION_AUTO_CANCEL = "SUBSCRIPTION_AUTO_CANCEL",
}
export type WebhookEventType = `${WebhookEventTypeEnum}` | WebhookEventTypeEnum;

export interface IWebhook extends ISharedEntityFields {
  eventType: WebhookEventType;
  url: string;
}

export type IWebhookCreate = ICreateEntity<IWebhook>;
