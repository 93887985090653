import { VerticalStack } from "@shopify/polaris";
import React, { useEffect } from "react";
import { OnSiteSetupPageHeader } from "./components/OnSiteSetupPageHeader";
import { DuplicateThemeBanner } from "./components/DuplicateThemeBanner";
import { AddSubscriptionWidgetCard } from "./components/AddSubscriptionWidgetCard";
import { AddCustomerPortalWidgetCard } from "./components/AddCustomerPortalWidgetCard";
import { HelpFooter } from "./components/HelpFoooter";
import { SetupStoreAccess } from "../../libs/store";

export const OnSiteSetupNew = () => {
  const { initialize } = SetupStoreAccess.useActions();
  useEffect(() => {
    initialize(true);
  }, []);
  return (
    <VerticalStack gap={"5"}>
      <OnSiteSetupPageHeader />
      <DuplicateThemeBanner />
      <AddSubscriptionWidgetCard />
      <AddCustomerPortalWidgetCard />
      <HelpFooter />
    </VerticalStack>
  );
};
