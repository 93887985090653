import { DateTime } from "luxon";

import { getLocale } from "./getLocale";
import { NestedKeyOf, localize } from "./sharedTranslations/localize";
import { checkIsSafari } from "./userAgentParsing";

const isSafari = checkIsSafari();

// TODO: Move all function params to an obj and assign to an interface
// TODO: Move this file and test to new folder
type ViewDateOptions = Intl.DateTimeFormatOptions & {
  locale?: Intl.LocalesArgument;
};

export function normalizeToDate(input: string | Date | null): Date {
  if (!input) {
    throw new Error("Invalid date: null or undefined");
  }
  if (input instanceof Date) {
    return input; // Already a Date object
  }
  const date = new Date(input); // Attempt to convert string to Date
  if (isNaN(date.getTime())) {
    throw new TypeError(`Invalid ISO date string: ${input}`);
  }
  return date;
}

export function toDays(str: string) {
  const [amount, dateType] = str.split(" ").map(el => el.trim());
  return +amount > 1
    ? `${amount} ${localize(`dates.${dateType}s` as NestedKeyOf<object>)}`
    : localize(`dates.${dateType}` as NestedKeyOf<object>);
}

export function toViewDate(date: Date | DateTime | string | null, options: ViewDateOptions = {}) {
  let inputDate: Date;

  if (!date) {
    return "N/A";
  }

  if (date instanceof DateTime) {
    inputDate = date.toJSDate();
  } else if (typeof date === "string") {
    inputDate = new Date(date);
    if (isNaN(inputDate.getTime())) {
      throw new TypeError(`Invalid date string: "${date}"`);
    }
  } else if (date instanceof Date) {
    inputDate = date;
  } else {
    throw new TypeError("Unsupported date type");
  }

  const { locale, ...formatOptions } = options;
  return new Intl.DateTimeFormat(locale ?? getLocale(), formatOptions).format(inputDate);
}

export function toViewLongDate(date: Date | DateTime | string | null, locale?: Intl.LocalesArgument) {
  try {
    const options = isSafari
      ? { weekday: "long", year: "numeric", month: "long", day: "numeric" }
      : { dateStyle: "full" };
    // dateStyle property not in core typescript def yet (works in all browsers though)
    return toViewDate(date, { ...options, locale } as any);
  } catch (error) {
    console.error(error);
    return toViewDate(date, { locale }); // just in case dateStyle property fails in an old browser
  }
}

export function toViewShortDate(date: Date | DateTime | string | null, locale?: Intl.LocalesArgument) {
  return toViewDate(date, { day: "numeric", month: "numeric", year: "2-digit", locale });
}

export function toViewVeryShortDate(date: Date | DateTime | null, locale?: Intl.LocalesArgument) {
  return toViewDate(date, { day: "numeric", month: "numeric", locale });
}

export function toViewMonthYearDate(date: Date | DateTime | null, locale?: Intl.LocalesArgument) {
  return toViewDate(date, { month: "numeric", year: "2-digit", locale });
}
