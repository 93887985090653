import { LegacyCard, LegacyStack, Select, TextField } from "@shopify/polaris";
import { IWebhook, WebhookEventType, WebhookEventTypeEnum } from "@smartrr/shared/entities/Webhook";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";

import { WebhookContainer } from "../../../components/elements/styles";

export const WebhookEventTypes: { label: string; value: string | WebhookEventType }[] = [
  { label: "Subscription activate", value: WebhookEventTypeEnum.SUBSCRIPTION_ACTIVATE },
  { label: "Subscription cancel", value: WebhookEventTypeEnum.SUBSCRIPTION_CANCEL },
  { label: "Subscription line update", value: WebhookEventTypeEnum.SUBSCRIPTION_LINE_UPDATE },
  { label: "Variant out of stock", value: WebhookEventTypeEnum.SUBSCRIPTION_ITEM_OUT_OF_STOCK },
  { label: "Subscription create", value: WebhookEventTypeEnum.SUBSCRIPTION_CREATE },
  { label: "Subscription failed payment", value: WebhookEventTypeEnum.SUBSCRIPTION_FAILED_PAYMENT },
  { label: "Subscription gift", value: WebhookEventTypeEnum.SUBSCRIPTION_GIFT },
  { label: "Subscription pause", value: WebhookEventTypeEnum.SUBSCRIPTION_PAUSE },
  { label: "Subscription auto resume", value: WebhookEventTypeEnum.SUBSCRIPTION_AUTO_RESUME },
  { label: "Subscription send now", value: WebhookEventTypeEnum.SUBSCRIPTION_SEND_NOW },
  { label: "Subscription set next date", value: WebhookEventTypeEnum.SUBSCRIPTION_SET_NEXT_DATE },
  { label: "Subscription skip", value: WebhookEventTypeEnum.SUBSCRIPTION_SKIP },
  { label: "Subscription successful payment", value: WebhookEventTypeEnum.SUBSCRIPTION_SUCCESSFUL_PAYMENT },
  { label: "Subscription unskip", value: WebhookEventTypeEnum.SUBSCRIPTION_UNSKIP },
  { label: "Subscription upcoming order", value: WebhookEventTypeEnum.SUBSCRIPTION_UPCOMING_ORDER },
  { label: "Payment method update", value: WebhookEventTypeEnum.PAYMENT_METHOD_UPDATE },
  { label: "Bundle Complete", value: WebhookEventTypeEnum.BUNDLE_COMPLETE },
  { label: "Subscription Auto Canceled", value: WebhookEventTypeEnum.SUBSCRIPTION_AUTO_CANCEL },
];

interface WebhookProps {
  webhook: IWebhook;
  updateWebhook: (webhook: IWebhook) => void;
  deleteWebhook: (webhook: IWebhook) => void;
}

export function Webhook(props: WebhookProps) {
  const { webhook, updateWebhook, deleteWebhook } = props;

  const [selectedEventType, setSelectedEventType] = useState(webhook.eventType);
  const [selectedUrl, setSelectedUrl] = useState(webhook.url);

  const handleSelectedEventType = useCallback(
    eventType => {
      setSelectedEventType(eventType);
      updateWebhook({ ...webhook, eventType });
    },
    [selectedEventType, webhook]
  );

  const handleSelectedURL = useCallback(
    value => {
      setSelectedUrl(value);
      updateWebhookURL(value);
    },
    [selectedUrl]
  );

  const updateWebhookURL = useCallback(
    debounce(url => updateWebhook({ ...webhook, url }), 5000),
    [webhook]
  );

  return (
    <WebhookContainer>
      <LegacyCard>
        <LegacyStack alignment="baseline" distribution="fillEvenly">
          <Select
            label="Event Types"
            options={WebhookEventTypes}
            onChange={handleSelectedEventType}
            value={selectedEventType}
          />
          <TextField
            autoComplete="off"
            label="URL"
            labelAction={{
              content: "Remove",
              onAction() {
                deleteWebhook(webhook);
              },
            }}
            onChange={handleSelectedURL}
            value={selectedUrl}
          />
        </LegacyStack>
      </LegacyCard>
    </WebhookContainer>
  );
}
