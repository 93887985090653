import { useEffect, useState } from "react";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { GetCustomerWithEventsAndActiveSubscriptionIdsQuery } from "@smartrr/shared/shopifyGraphQL/api";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { AppReqResponse } from "@smartrr/shared/req";

/**
 * This hook queries graphQL for customer
 * data and returns the loading state,
 * customer data, and order count
 */
export const useShopifyCustomerData = (customerShopifyId: string) => {
  const { addToast } = useToast();
  const [shopifyCustomerData, setShopifyCustomerData] =
    useState<GetCustomerWithEventsAndActiveSubscriptionIdsQuery["customer"]>();
  const [isShopifyCustomerDataLoading, setIsShopifyCustomerDataLoading] = useState(true);

  useEffect(() => {
    const fetchShopifyCustomerData = async () => {
      setIsShopifyCustomerDataLoading(true);
      const response: AppReqResponse<GetCustomerWithEventsAndActiveSubscriptionIdsQuery> =
        await typedFrontendVendorApi.getReq(`/customer/:customerShopifyId/shopify-customer-data`, {
          params: {
            customerShopifyId: ensureShopifyGid("Customer", customerShopifyId),
          },
        });

      if (response.type === "error") {
        console.error(`Error getting customer data: ${response.message}`);
        addToast("Error getting customer data", true);
        setIsShopifyCustomerDataLoading(false);
        return;
      }

      if (response.type !== "success") {
        console.error(`Error getting customer data`);
        addToast("Error getting customer data", true);
        setIsShopifyCustomerDataLoading(false);
        return;
      }

      if (response.type === "success") {
        const customerData = response.body.customer;
        if (customerData) {
          setShopifyCustomerData(customerData);
          setIsShopifyCustomerDataLoading(false);
        }
      }
    };
    void fetchShopifyCustomerData();
  }, [customerShopifyId]);
  return { isShopifyCustomerDataLoading, shopifyCustomerData };
};
