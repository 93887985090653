import { Banner, Checkbox, ChoiceList, LegacyStack, Modal, Text, TextField } from "@shopify/polaris";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import {
  ICustomerPortalThemeForm,
  ProductScrollType,
  ResponsiveFontStyling,
} from "@smartrr/shared/entities/CustomerPortalTheme";
import React, { useCallback, useEffect, useState } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import {
  ActionsTitle,
  ColorValues,
  ModernSettingsInputContainer,
  TextFieldWrapper,
  WelcomeHeader,
} from "./libs/styles";
import { isFeatureAvailable } from "../../../../components/authorization/featureAccess";
import { Accordion } from "../../../components/Accordion";
import { ColorPicker } from "../../../components/ColorPicker";
import { ColorPickerContainer } from "../../../styles";
import { PasswordlessSettings } from "./components/PasswordlessSettings";
import { ThemingStoreAccess } from "../../../store";

interface IAdvancedSettings {
  themeValues: ICustomerPortalThemeForm;
  setThemeFieldValue: any;
}

const AdvancedSettings = ({ themeValues, setThemeFieldValue }: IAdvancedSettings) => {
  const { initialThemeValues } = ThemingStoreAccess.useThemeValues();
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  /**
   * move this to its own component
   */
  const ModernSettingsInput: React.FC<{ title: string; valString: string; val: boolean }> = ({
    title,
    valString,
    val,
  }) => {
    return (
      <ModernSettingsInputContainer>
        <div>
          <Checkbox label={title} checked={val} onChange={() => setThemeFieldValue(valString, !val)} />
        </div>
      </ModernSettingsInputContainer>
    );
  };

  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);

  const onCancelCallback = useCallback(() => {
    setThemeFieldValue("isPasswordless", true);
    setShowWarningModal(prev => !prev);
  }, [setThemeFieldValue]);

  useEffect(() => {
    if (initialThemeValues?.isPasswordless && !themeValues.isPasswordless) {
      setShowWarningModal(true);
    }
  }, [themeValues]);

  return (
    <div>
      {/* With passwordless being deprecated, we only want to surface the settings if theme is already using passwordless */}
      {isFeatureAvailable(FeatureEnum.PASSWORDLESS, user, activePlan) && initialThemeValues?.isPasswordless ? (
        <Accordion title="customer login">
          <LegacyStack vertical>
            <WelcomeHeader>Choose versus Shopify&apos;s traditional login or passwordless login.</WelcomeHeader>
            <PasswordlessSettings themeValues={themeValues} />
          </LegacyStack>
        </Accordion>
      ) : null}

      <Accordion title="welcome info">
        <LegacyStack vertical>
          <WelcomeHeader>Customize how you want to greet your customers on their account portals.</WelcomeHeader>
          <TextFieldWrapper>
            <TextField
              label="Welcome word"
              value={themeValues.modernThemeSettings.welcomeInfo.welcomeMessage}
              onChange={val => {
                setThemeFieldValue("modernThemeSettings.welcomeInfo.welcomeMessage", val);
              }}
              autoComplete="off"
            />
          </TextFieldWrapper>
          <ModernSettingsInput
            title="Display customer first name"
            val={themeValues.modernThemeSettings.welcomeInfo.customerFirstName}
            valString="modernThemeSettings.welcomeInfo.customerFirstName"
          />
          <ModernSettingsInput
            title="Display customer email"
            val={themeValues.modernThemeSettings.welcomeInfo.customerEmail}
            valString="modernThemeSettings.welcomeInfo.customerEmail"
          />
          <ModernSettingsInput
            title="Display 'Customer since' date"
            val={themeValues.modernThemeSettings.welcomeInfo.customerSinceDate}
            valString="modernThemeSettings.welcomeInfo.customerSinceDate"
          />
        </LegacyStack>
      </Accordion>

      <Accordion title=" subscription management">
        <WelcomeHeader style={{ marginTop: "22px", marginBottom: "24px" }}>
          Define which actions you want your customer to be able to take when managing their subscriptions in the
          customer account portal.
        </WelcomeHeader>
        <LegacyStack vertical>
          <ActionsTitle>Next order actions</ActionsTitle>
          <ModernSettingsInput
            title={"Order now"}
            val={themeValues.modernThemeSettings.nextOrderActions.sendNow}
            valString="modernThemeSettings.nextOrderActions.sendNow"
          />
          <ModernSettingsInput
            title={"Skip"}
            val={themeValues.modernThemeSettings.nextOrderActions.skip}
            valString="modernThemeSettings.nextOrderActions.skip"
          />
          <ModernSettingsInput
            title={"Gift"}
            val={themeValues.modernThemeSettings.nextOrderActions.gift}
            valString="modernThemeSettings.nextOrderActions.gift"
          />
          <ModernSettingsInput
            title={"Swap item"}
            val={themeValues.modernThemeSettings.nextOrderActions.swap}
            valString="modernThemeSettings.nextOrderActions.swap"
          />
          <ModernSettingsInput
            title={"Delay date"}
            val={themeValues.modernThemeSettings.nextOrderActions.delay}
            valString="modernThemeSettings.nextOrderActions.delay"
          />
          <ModernSettingsInput
            title={"Edit address"}
            val={themeValues.modernThemeSettings.nextOrderActions.editAddress}
            valString="modernThemeSettings.nextOrderActions.editAddress"
          />
          <ActionsTitle>Manage subscription actions</ActionsTitle>
          <ModernSettingsInput
            title={"Swap item"}
            val={themeValues.modernThemeSettings.subscriptionActions.subscriptionSwap}
            valString="modernThemeSettings.subscriptionActions.subscriptionSwap"
          />
          <ModernSettingsInput
            title={"Edit frequency "}
            val={themeValues.modernThemeSettings.subscriptionActions.editFrequency}
            valString="modernThemeSettings.subscriptionActions.editFrequency"
          />
          <ModernSettingsInput
            title={"Set next date"}
            val={themeValues.modernThemeSettings.subscriptionActions.setNextOrderDate}
            valString="modernThemeSettings.subscriptionActions.setNextOrderDate"
          />
          <ModernSettingsInput
            title={"Edit address"}
            val={themeValues.modernThemeSettings.subscriptionActions.editSubscriptionAddress}
            valString="modernThemeSettings.subscriptionActions.editSubscriptionAddress"
          />
          <ModernSettingsInput
            title={"Edit payment"}
            val={themeValues.modernThemeSettings.subscriptionActions.editPayment}
            valString="modernThemeSettings.subscriptionActions.editPayment"
          />
          <ModernSettingsInput
            title={"Pause"}
            val={themeValues.modernThemeSettings.subscriptionActions.pause}
            valString="modernThemeSettings.subscriptionActions.pause"
          />
          <ModernSettingsInputContainer>
            <div>
              <Checkbox label={"Cancel"} disabled checked={true} />
            </div>
          </ModernSettingsInputContainer>
        </LegacyStack>
        <div style={{ color: "#6D7175", marginLeft: "18px", marginTop: "5px" }}>This option is required</div>
      </Accordion>

      <Accordion title=" product display">
        <WelcomeHeader style={{ marginTop: "22px", marginBottom: "24px" }}>
          Choose how products display in the &quot;Manage next order&quot; and &quot;Manage subscription&quot;
          modals.
        </WelcomeHeader>
        <LegacyStack vertical>
          <ChoiceList
            title="Product display"
            titleHidden={true}
            choices={[
              {
                value: "horizontal",
                label: "Horizontal scroll",
              },
              {
                value: "vertical",
                label: "Vertical scroll",
              },
            ]}
            selected={[themeValues.modernThemeSettings.productScrollType]}
            onChange={selected => {
              if (selected.length) {
                const scrollType: ProductScrollType = selected[0] as ProductScrollType;

                switch (scrollType) {
                  case "horizontal":
                  case "vertical": {
                    setThemeFieldValue("modernThemeSettings.productScrollType", scrollType);
                    break;
                  }
                  default: {
                    setThemeFieldValue("modernThemeSettings.productScrollType", "horizontal");
                    break;
                  }
                }
              }
            }}
          />
        </LegacyStack>
      </Accordion>

      <Accordion title=" responsive font styling ">
        <WelcomeHeader style={{ marginTop: "22px", marginBottom: "24px" }}>
          Scales the body font size proportionally to a user&apos;s screen to keep product titles on one line.{" "}
          <br />
          (Good for products with long titles)
        </WelcomeHeader>
        <LegacyStack vertical>
          <ChoiceList
            title="Font styling"
            titleHidden={true}
            choices={[
              {
                value: "static",
                label: "Static",
              },
              {
                value: "responsive",
                label: "Responsive",
              },
            ]}
            selected={[themeValues.modernThemeSettings.fontSizingType]}
            onChange={selected => {
              if (selected.length) {
                const styleType: ResponsiveFontStyling = selected[0] as ResponsiveFontStyling;

                switch (styleType) {
                  case "responsive":
                  case "static": {
                    setThemeFieldValue("modernThemeSettings.fontSizingType", styleType);
                    break;
                  }
                  default: {
                    setThemeFieldValue("modernThemeSettings.fontSizingType", "static");
                    break;
                  }
                }
              }
            }}
          />
        </LegacyStack>
      </Accordion>

      <Accordion title=" show / hide">
        <WelcomeHeader style={{ marginTop: "22px", marginBottom: "24px" }}>
          Use these checkboxes to conditionally display certain parts of the account portal.
        </WelcomeHeader>
        <LegacyStack vertical>
          <Checkbox
            label="Variant names"
            helpText='Variant name will only show when there is 1 item in the subscription. Otherwise, will show: "x items". '
            checked={themeValues.modernThemeSettings.showHide.showVariantName}
            onChange={() =>
              setThemeFieldValue(
                "modernThemeSettings.showHide.showVariantName",
                !themeValues.modernThemeSettings.showHide.showVariantName
              )
            }
          />
        </LegacyStack>
        <LegacyStack vertical>
          <Checkbox
            label="Account portal menu"
            helpText="Navigation bar that allows users to quick-jump to sections within the account portal."
            checked={themeValues.modernThemeSettings.showHide.showPortalMenu}
            onChange={() =>
              setThemeFieldValue(
                "modernThemeSettings.showHide.showPortalMenu",
                !themeValues.modernThemeSettings.showHide.showPortalMenu
              )
            }
          />
        </LegacyStack>
        <LegacyStack vertical>
          <Checkbox
            label='"See all upcoming orders"'
            helpText='On "Next order" card—the ability for customers to click and see a full list of their upcoming orders for the next six months.'
            checked={themeValues.modernThemeSettings.showHide.showUpcomingOrders}
            onChange={() =>
              setThemeFieldValue(
                "modernThemeSettings.showHide.showUpcomingOrders",
                !themeValues.modernThemeSettings.showHide.showUpcomingOrders
              )
            }
          />
        </LegacyStack>

        <LegacyStack vertical>
          <Checkbox
            label="Discount code input"
            helpText="The ability for customers to add discount codes to their next order & subscriptions."
            checked={themeValues.modernThemeSettings.showHide.showDiscountCodeInput}
            onChange={() =>
              setThemeFieldValue(
                "modernThemeSettings.showHide.showDiscountCodeInput",
                !themeValues.modernThemeSettings.showHide.showDiscountCodeInput
              )
            }
          />
        </LegacyStack>
        <LegacyStack vertical>
          <Checkbox
            label="Section titles"
            helpText='Headings above each account portal section, i.e., "Subscriptions, Order History".'
            checked={themeValues.modernThemeSettings.showHide.showSectionTitles}
            onChange={() =>
              setThemeFieldValue(
                "modernThemeSettings.showHide.showSectionTitles",
                !themeValues.modernThemeSettings.showHide.showSectionTitles
              )
            }
          />
        </LegacyStack>
        <LegacyStack vertical>
          <Checkbox
            label="Add item to subscription"
            helpText="The ability for customers to add products to their subscription as subscription items."
            checked={themeValues.modernThemeSettings.showHide.showAddItemAsSub}
            onChange={() =>
              setThemeFieldValue(
                "modernThemeSettings.showHide.showAddItemAsSub",
                !themeValues.modernThemeSettings.showHide.showAddItemAsSub
              )
            }
          />
        </LegacyStack>
      </Accordion>

      <Accordion title=" badge overrides">
        <WelcomeHeader style={{ marginTop: "22px", marginBottom: "20px" }}>
          Override the background color for badges that show subscription states: Active, Paused, and Canceled.
        </WelcomeHeader>

        <ColorPickerContainer>
          <ColorPicker
            defaultColor={themeValues.modernActiveBadgeColor}
            onChange={color => setThemeFieldValue("modernActiveBadgeColor", color)}
          />
          <ColorValues>
            <div>{"'Active'"} badge background</div>
            <div>{themeValues.modernActiveBadgeColor}</div>
          </ColorValues>
        </ColorPickerContainer>

        <ColorPickerContainer>
          <ColorPicker
            defaultColor={themeValues.modernPausedBadgeColor}
            onChange={color => setThemeFieldValue("modernPausedBadgeColor", color)}
          />
          <ColorValues>
            <div>{"'Paused'"} badge background</div>
            <div>{themeValues.modernPausedBadgeColor}</div>
          </ColorValues>
        </ColorPickerContainer>

        <ColorPickerContainer>
          <ColorPicker
            defaultColor={themeValues.modernCancelledBadgeColor}
            onChange={color => setThemeFieldValue("modernCancelledBadgeColor", color)}
          />
          <ColorValues>
            <div>{"'Canceled'"} badge background</div>
            <div>{themeValues.modernCancelledBadgeColor}</div>
          </ColorValues>
        </ColorPickerContainer>
      </Accordion>
      {showWarningModal ? (
        <Modal
          sectioned
          title="Warning"
          open={showWarningModal}
          onClose={() => setShowWarningModal(prev => !prev)}
          primaryAction={{
            content: "Continue",
            onAction: () => setShowWarningModal(prev => !prev),
          }}
          secondaryActions={[{ content: "Cancel", destructive: true, onAction: onCancelCallback }]}
        >
          <Banner status="warning">
            <Text as="span" variant="bodyMd">
              If you switch back to traditional from passwordless login, all customers will be required to reset
              their passwords the next time they go to log in.
            </Text>
          </Banner>
        </Modal>
      ) : null}
    </div>
  );
};

export default AdvancedSettings;
