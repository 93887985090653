import { useEffect, useState } from "react";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";

export const useCustomerOrderCount = (customerShopifyId: string): number => {
  const [orderCount, setOrderCount] = useState<number>(0);
  const customer = useSmartrrVendorSelector(state =>
    state.customerRelationships.data.find(
      custRel => custRel.shopifyId === ensureShopifyGid("Customer", customerShopifyId)
    )
  );

  useEffect(() => {
    const fetchOrders = async (custRel: ICustomerRelationshipShallow) => {
      /**
       * Setting local variables to
       * handle pagination
       */
      let allOrders: any[] = [];
      let pageNumber = 0;
      let totalPages = 0;
      try {
        do {
          const res = await typedFrontendVendorApi.getReq("/customer/:custRelId/orders", {
            params: {
              custRelId: custRel.id,
            },
            query: {
              pageNumber,
            },
          });

          if (res.type === "success") {
            const { data, totalPages: fetchedTotalPages } = res.body;
            allOrders = [...allOrders, ...data];
            totalPages = fetchedTotalPages;
            pageNumber++;
          }
        } while (pageNumber <= totalPages);
      } catch (error) {
        /**
         * Not adding toast here as we're moving away from
         * error toasts as per Built For Shopify Criteria
         * TODO: Update with new FE error handling
         */
        console.error("Error fetching orders:", error);
      }
      return allOrders;
    };
    if (customer) {
      fetchOrders(customer)
        .then(allOrders => {
          setOrderCount(allOrders.length);
        })
        .catch(error => {
          console.error("Error getting orders:", error);
        });
    }
  }, [customer]);
  return orderCount;
};
