import { Tabs, Page, TabProps } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";

import { BADGE_BASE_TEXT, ONSITE_SETUP_TAB_LABEL, SUBSCRIPTION_SETUP_TAB_LABEL } from "./libs/constants";
import useAdminSetupRouteLocalData from "./libs/hooks/useAdminSetupRouteLocalData";
import useAdminSetupRemoteData from "./libs/hooks/useAdminSetupRouteRemoteData";
import { SetupTabsEnum } from "./libs/types";
import { OnsiteSetup } from "./Tabs/OnSiteSetup";
import { SubscriptionSetup } from "./Tabs/SubscriptionSetup";
import { Spinner } from "../components/elements/Spinner";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { OnSiteSetupNew } from "./Tabs/OnSiteSetupNew";
import { SetupStoreAccess } from "./libs/store";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

export function AdminSetupRoute(): JSX.Element {
  const {
    setup,
    purchasables,
    isPurchasablesLoading,
    onSiteSetupCount,
    subscriptionSetupCount,
    setOnSiteSetupCount,
  } = useAdminSetupRouteLocalData();

  const {
    loadProductsAndVariantsFromShopify,
    themes,
    isLoadingThemes,
    linkedProductIds,
    linkedVariantIds,
    isProductsPageLoading,
    isVariantsPageLoading,
    setIsProductsPageLoading,
    setIsVariantsPageLoading,
  } = useAdminSetupRemoteData(setup);

  const [selectedTab, setSelectedTab] = useState<number>(SetupTabsEnum.SUBSCRIPTION);
  const { addToast } = useToast();
  const errors = SetupStoreAccess.useErrors();

  const { updatedSetupSteps } = useSmartrrFlags();

  const handleTabChange = useCallback(selectedTabIndex => setSelectedTab(selectedTabIndex), []);

  const renderTabsSwitch = (selectedTab: SetupTabsEnum) => {
    switch (selectedTab) {
      case SetupTabsEnum.SUBSCRIPTION: {
        return (
          <SubscriptionSetup
            linkedProductIds={linkedProductIds}
            isProductsPageLoading={isProductsPageLoading}
            isVariantsPageLoading={isVariantsPageLoading}
            linkedVariantIds={linkedVariantIds}
            loadProductsAndVariantsFromShopify={loadProductsAndVariantsFromShopify}
            setIsProductsPageLoading={setIsProductsPageLoading}
            setIsVariantsPageLoading={setIsVariantsPageLoading}
            setSelectedTab={setSelectedTab}
          />
        );
      }
      case SetupTabsEnum.ONSITE: {
        if (updatedSetupSteps) {
          return <OnSiteSetupNew />;
        }
        return (
          <OnsiteSetup themes={themes} setOnSiteSetupCount={setOnSiteSetupCount} purchasables={purchasables} />
        );
      }
      default: {
        return null;
      }
    }
  };

  const AdminSetUpRouteTabs: TabProps[] = [
    {
      id: "subscription-setup",
      content: SUBSCRIPTION_SETUP_TAB_LABEL,
      badge: `${subscriptionSetupCount}${BADGE_BASE_TEXT}`,
      panelID: "subs-setup-content",
    },
    {
      id: "on-site-setup",
      content: ONSITE_SETUP_TAB_LABEL,
      // Remove condition once `updated-setup-steps` / `updatedSetupSteps` is archived
      ...(updatedSetupSteps ? undefined : { badge: `${onSiteSetupCount}${BADGE_BASE_TEXT}` }),
      panelID: "on-site-setup-content",
    },
  ];

  // Remove condition once `updated-setup-steps` / `updatedSetupSteps` is archived
  useEffect(() => {
    if (updatedSetupSteps && errors.showError && errors.message) {
      addToast(errors.message, true);
    }
  }, [updatedSetupSteps, errors]);

  if (isLoadingThemes || isPurchasablesLoading) {
    return <Spinner />;
  }

  return (
    <Page narrowWidth>
      <Tabs tabs={AdminSetUpRouteTabs} selected={selectedTab} onSelect={handleTabChange} fitted>
        <div data-testid="render-tabs-container">{renderTabsSwitch(selectedTab)}</div>
      </Tabs>
    </Page>
  );
}
