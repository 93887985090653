import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { fetchShopifyThemes, ThemeValidationAssetFiles, validateShopifyTheme } from "../utils/themes";
import { ISetup } from "@smartrr/shared/entities/Organization";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { loadSellingPlanGroupProductsAndVariants } from "../hooks/useAdminSetupRouteRemoteData";
import { NestedPath, PathValue, setNestedProperty } from "@smartrr/shared/utils/setNestedProperty";
import { cloneDeep } from "lodash";

interface SetupStore {
  isLoading: boolean;
  themes: ISmartrrShopTheme[];
  setup: ISetup | null;
  /** Selling plan being used within setup sets, initially pulled from setup.selectedSellingPlanId */
  sellingPlan: {
    productIds: string[];
    variantIds: string[];
  };
  error: {
    showError: boolean;
    message: string;
  };
  actions: {
    initialize(initializeAll?: boolean): void;
    update<T extends NestedPath<ISetup>, P extends PathValue<ISetup, T>>({
      path,
      val,
    }: {
      path: T;
      val: P;
    }): void;
    fetchSellingPlanProductsAndVariants(sellingPlanId: string): void;
    validateTheme(themeId: string | number, file: ThemeValidationAssetFiles): Promise<boolean>;
  };
  internal: {
    whileLoading(loadFn: () => Promise<void>): Promise<void>;
    fetchThemes(): void;
    fetchSetup(): Promise<ISetup | null>;
    clearError(): void;
    logError(message: string): void;
  };
}

const useSetUpStore = create<SetupStore>()(
  immer((set, get) => ({
    isLoading: false,
    themes: [],
    setup: null,
    sellingPlan: {
      productIds: [],
      variantIds: [],
    },
    error: {
      showError: false,
      message: "",
    },
    actions: {
      async initialize(initializeAll) {
        const { fetchSellingPlanProductsAndVariants } = get().actions;
        const { whileLoading, fetchSetup, fetchThemes } = get().internal;

        await whileLoading(async () => {
          const setup = await fetchSetup();
          // This store can and should be used in place of loadSetup, not everything needs to be fetched if we're only looking for org.setup
          if (initializeAll) {
            fetchThemes();

            if (setup?.selectedSellingPlanId) {
              fetchSellingPlanProductsAndVariants(setup.selectedSellingPlanId);
            }
          }
        });
      },
      async update({ path, val }) {
        const { setup, internal } = get();
        if (setup) {
          await internal.whileLoading(async () => {
            const clonedSetup = cloneDeep(setup); // setNestedProperty mutates the provided obj
            const updatedSetup = setNestedProperty(clonedSetup, path, val);
            const res = await typedFrontendVendorApi.putReq("/setup", {
              reqBody: {
                setup: updatedSetup,
                // TODO: verify if `creatingSubscriptionProgram`, `creatingSubscriptionProgramProducts`, and `installingWidgets` need to be passed as parameters for BE logging still
              },
            });

            if (res.type === "success") {
              set({
                setup: res.body,
              });
            }
          });

          internal.logError("Error updating shop's setup");
        }
      },
      async fetchSellingPlanProductsAndVariants(sellingPlanId) {
        const { internal } = get();
        try {
          const { productIds, variantIds } = await loadSellingPlanGroupProductsAndVariants(sellingPlanId);
          set({
            sellingPlan: {
              productIds,
              variantIds,
            },
          });
        } catch (error) {
          internal.logError(error.message);
        }
      },
      async validateTheme(id, file) {
        if (!id) {
          // If no theme is selected, we don't need to validate
          return true;
        }
        const { internal } = get();
        const themeId = +id;
        let isValid = true;
        try {
          await internal.whileLoading(async () => {
            const res = await validateShopifyTheme(themeId, file);
            isValid = res;
          });
        } catch {
          internal.logError("Error validating theme, please try again.");
        }
        return isValid;
      },
    },
    internal: {
      async whileLoading(loadfn: () => Promise<void>): Promise<void> {
        set({
          isLoading: true,
        });
        await loadfn();
        set({ isLoading: false });
      },
      async fetchThemes() {
        const { themes: storedThemes, internal } = get();
        try {
          if (!storedThemes.length) {
            const themes = await fetchShopifyThemes();
            set({
              themes,
            });
          }
        } catch (error) {
          internal.logError(error.message);
        }
      },
      async fetchSetup() {
        const { setup: storedSetup, internal } = get();
        if (!storedSetup) {
          const res = await typedFrontendVendorApi.getReq("/setup");

          if (res.type === "success") {
            internal.clearError();
            set({
              setup: res.body,
            });
            return res.body;
          }

          internal.logError("Error fetching shop's setup");
        }
      },
      clearError() {
        set({
          error: {
            message: "",
            showError: false,
          },
        });
      },
      logError(message) {
        set(draft => ({
          ...draft,
          error: {
            showError: true,
            message: draft.error.message ? `${draft.error.message}, ${message}` : message,
          },
        }));
      },
    },
  }))
);

const initialState = useSetUpStore.getState();

export const SetupStoreAccess = {
  useLoading: () => useSetUpStore(state => state.isLoading),
  useActions: () => useSetUpStore(state => state.actions),
  useThemes: () => useSetUpStore(state => state.themes),
  useSetup: () => useSetUpStore(state => state.setup),
  useErrors: () => useSetUpStore(state => state.error),
  testing: {
    state: useSetUpStore.getState,
    actions: useSetUpStore.getState().actions,
    reset(state?: Partial<SetupStore>) {
      useSetUpStore.setState({ ...initialState, ...state });
    },
  },
};
